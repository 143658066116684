import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";
import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";

function OurTeam({ data }) {
  const jobs = data.allStrapiJobPostions.edges.map(({ node }) => node);
  return (
    <Layout>
      <SEO title="Our Team" />
      {data.allStrapiOurteam.edges.map(({ node }) => {
        return (
          <React.Fragment key={node}>
            <Masthead
              mhtitle={node.ourteam_mh.mh_title}
              mhsubtitle={node.ourteam_mh.mh_subtitle}
              mhbackground={
                node.ourteam_mh.mh_bg.childImageSharp.fluid.originalImg
              }
            />

            <Twocol
              title={node.ourteam_text_image.txtimg_title}
              description={node.ourteam_text_image.txtimg_description}
              order={node.ourteam_text_image.txtimg_order}
              rowimg={
                node.ourteam_text_image.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />

            <Twocol
              title={node.ourteam_text_image2.txtimg_title}
              description={node.ourteam_text_image2.txtimg_description}
              order={node.ourteam_text_image2.txtimg_order}
              rowimg={
                node.ourteam_text_image2.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />

            <Twocol
              title={node.ourteam_text_image3.txtimg_title}
              description={node.ourteam_text_image3.txtimg_description}
              order={node.ourteam_text_image3.txtimg_order}
              rowimg={
                node.ourteam_text_image3.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />
            <Twocol
              title={node.ourteam_text_image4.txtimg_title}
              description={node.ourteam_text_image4.txtimg_description}
              order={node.ourteam_text_image4.txtimg_order}
              rowimg={
                node.ourteam_text_image4.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />

            <Craftsmanship
              title={node.ourteam_craftmanship.craft_title}
              description={node.ourteam_craftmanship.craft_description}
            />

            <Twocol
              title={node.ourteam_text_image5.txtimg_title}
              description={node.ourteam_text_image5.txtimg_description}
              order={node.ourteam_text_image5.txtimg_order}
              rowimg={
                node.ourteam_text_image5.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />
            <Twocol
              title={node.ourteam_text_image6.txtimg_title}
              description={node.ourteam_text_image6.txtimg_description}
              order={node.ourteam_text_image6.txtimg_order}
              rowimg={
                node.ourteam_text_image6.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />
            <Twocol
              title={node.ourteam_text_image7.txtimg_title}
              description={node.ourteam_text_image7.txtimg_description}
              order={node.ourteam_text_image7.txtimg_order}
              rowimg={
                node.ourteam_text_image7.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />
            <Twocol
              title={node.ourteam_text_image8.txtimg_title}
              description={node.ourteam_text_image8.txtimg_description}
              order={node.ourteam_text_image8.txtimg_order}
              rowimg={
                node.ourteam_text_image8.txtimg_img.childImageSharp.fluid
                  .originalImg
              }
            />

            <Twocol
              title={node.ourteam_join_team_text_image.txtimg_title}
              description={node.ourteam_join_team_text_image.txtimg_description}
              order={node.ourteam_join_team_text_image.txtimg_order}
              rowimg={
                node.ourteam_join_team_text_image.txtimg_img.childImageSharp
                  .fluid.originalImg
              }
            >
              {jobs.length > 0 && (
                <ul className="mt-4 list-disc pl-10">
                  {jobs.map(job => {
                    const { job_title, link_to, id } = job.job_collections;
                    return (
                      <li key={id} className="mt-5 first:mt-0">
                        <a
                          href={link_to}
                          target="_blank"
                          rel="noreferrer"
                          className="teal uppercase font-semibold hover:opacity-50 transition duration-300 ease-in-out"
                        >
                          {job_title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Twocol>
          </React.Fragment>
        );
      })}
    </Layout>
  );
}

export default OurTeam;

export const query = graphql`
  {
    allStrapiOurteam {
      edges {
        node {
          id # id of the node
          ourteam_mh {
            mh_subtitle
            mh_bg {
              publicURL
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
            mh_title
          }
          ourteam_craftmanship {
            craft_title
            craft_description
            craft_bg {
              publicURL
            }
          }
          ourteam_text_image {
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
            txtimg_order
            txtimg_title
          }
          ourteam_text_image2 {
            txtimg_description
            txtimg_order
            txtimg_title
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          ourteam_text_image3 {
            txtimg_title
            txtimg_order
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          ourteam_text_image4 {
            txtimg_order
            txtimg_title
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          ourteam_text_image5 {
            txtimg_order
            txtimg_title
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          ourteam_text_image6 {
            txtimg_order
            txtimg_title
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          ourteam_text_image7 {
            txtimg_order
            txtimg_title
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          ourteam_text_image8 {
            txtimg_order
            txtimg_title
            txtimg_description
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }

          ourteam_join_team_text_image {
            txtimg_description
            txtimg_order
            txtimg_title
            txtimg_img {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
        }
      }
    }
    allStrapiJobPostions {
      edges {
        node {
          job_collections {
            job_title
            link_to
            id
          }
        }
      }
    }
  }
`;
